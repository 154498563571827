import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { storeAgentStatus } from '../../containers/AgentStatuses/services'
import { getAgent, updateAgent } from '../../containers/Agents/services'
import { handleError } from '../../helpers'

export default function AgentStatusChip() {
    const currentUser = useSelector(state => state.auth.user)
    const [agent, setAgent] = useState(null)
    
    useEffect(() => {
        !agent && currentUser.role === 'agent' && getAgent(currentUser.id)
            .then(res => setAgent(res.data.data))
            .catch(error => handleError(error))
    }, [ agent, currentUser ])

    const handleToggle = () => {
        const newStatus = (agent && agent.is_available) ? 0 : 1
        storeAgentStatus({
            agent_id: currentUser.id,
            is_active: newStatus,
            is_system: 0
        })
        .then(() => setAgent(null))
        .catch(error => handleError(error))
    }

    if(currentUser.role !== 'agent' || !agent) return;

    return (
        <React.Fragment>
            <Container 
                active={agent && agent.is_available}
                onClick={handleToggle}
            >
                {(agent && agent.is_available) ? (
                    <p className='mb-0'>En Linea</p>
                ) : (
                    <p className='mb-0'>Fuera de Linea</p>
                )}
            </Container>
        </React.Fragment>
    )
}

const Container = styled.div`
    position: absolute;
    top: 40px;
    right: 10px;
    z-index: 100;
    background-color: ${props => props.active ? '#5db413' : '#333'};
    color: #fff;
    padding: 7px 15px;
    border-radius: 15px;
    cursor: pointer;
    &:hover {
        background-color: ${props => props.active ? '#488a0f' : '#888'};
    }
`