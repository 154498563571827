import React, { useState } from 'react'
import { Badge, Button } from 'reactstrap'
import { Divider, Empty, Popconfirm, Table, Tooltip, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faCopy, faEraser, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import RegisterSaleModal from './RegisterSaleModal'

import { getClientUri } from '../../../services/springAvirtualApi'
import { updateConversation } from '../services'
import { handleError, number } from '../../../helpers'

export default function ConversationsTable({ conversations, reload }) {	
	const [registerSaleModal, setRegisterSaleModal] = useState(null)

	const columns = [
		{
			title: 'Id',
			dataIndex: 'id',
			responsive: ['md'],
		},
		{
			title: 'Cliente',
			dataIndex: 'lead',
			render: t => !t ? <em>Sin registro</em> : (
				<span className='text-link' onClick={() => handleOpenChat(t.mobile)}>
					{t.mobile}
				</span>
			)
		},
		{
			title: 'Asesor',
			dataIndex: 'agent',
			render: t => t ? `${t.name} (${t.document})` : <em>Sin registro</em>,
			responsive: ['md'],
		},
		{
			title: 'Punto de Venta',
			dataIndex: 'link',
			render: t => (t && t.store) ? `${t.store.name} (${t.store.code})` : <em>Sin registro</em>,
			responsive: ['md'],
		},
		{
			title: 'Encuesta',
			width: '120px',
			dataIndex: 'survey',
			render: (t, r) => t ? `Puntaje: ${t.score}` : (
				<React.Fragment>
					<em className='small'>Pend.</em>{" "}
					<Tooltip title="Copiar enlace de encuesta" className='text-link' onClick={() => copySurveyLink(r.id)}>
						<FontAwesomeIcon icon={faCopy} />
					</Tooltip>
				</React.Fragment>
			)
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			width: '90px',
			render: (t,r) => (
				<React.Fragment>
					<Badge color={t.color}>{t.label}</Badge>
				</React.Fragment>
			)
		},
		{
			title: 'Venta Asociada',
			dataIndex: 'invoice_url',
			width: '130px',
			render: (t, r) => t ? (
				<React.Fragment>
					<Popconfirm
						title="Esta acción no se puede deshacer"
						okText="Eliminar"
						cancelText="Cancelar"
						okButtonProps={{ danger: true }}
						onConfirm={() => handleUpdate(r.id, {invoice_url: ''})}
					>
						<Tooltip title="Borrar Venta">
							<FontAwesomeIcon className='text-link' icon={faEraser} />{" "}
						</Tooltip>
					</Popconfirm>
					<Divider type="vertical" />
					<a href={t} target='_blank' rel="noreferrer">Ver adjunto</a>{" "}
				</React.Fragment>
			) : (
				<Button color='primary' outline className='fs-12' onClick={() => setRegisterSaleModal(r)}>
					Registrar Factura
				</Button>
			)
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					{/* { !r.cancelled_at && (
						<Popconfirm
							title="¿Desea marcar como cancelada?"
							okText="Anular"
							cancelText="Cancelar"
							okButtonProps={{ danger:true }}
							onConfirm={() => {
								handleUpdate(r.id, { cancelled_at: moment().format('YYYY-MM-DD HH:mm:ss') })
							}}
						>
							<Tooltip title="Cancelar Conversación" className='text-link'>
								<FontAwesomeIcon icon={faToggleOff} />{" "}
								<span className='hide-sm'>Cancelar</span>
							</Tooltip>
						</Popconfirm>
					)}					 */}
					{ !r.completed_at && (
						<React.Fragment>
							<Divider type="vertical" />
							<Popconfirm
								title="¿Desea marcar como finalizada?"
								okText="Finalizar"
								cancelText="Cancelar"
								okButtonProps={{ danger:true }}
								onConfirm={() => {
									handleUpdate(r.id, { completed_at: moment().format('YYYY-MM-DD HH:mm:ss') })
								}}
							>
								<Tooltip title="Finalizada" className='text-link'>
									<FontAwesomeIcon icon={faCheckSquare} />{" "}
									<span className='hide-sm'>Finalizar</span>
								</Tooltip>
							</Popconfirm>
						</React.Fragment>
					)}							
					{ (r.cancelled_at || r.completed_at) && (
						<React.Fragment>
							<Divider type="vertical" />
							<Popconfirm
								title="¿Desea abrir de nuevo la conversación?"
								okText="Abrir de nuevo"
								cancelText="Cancelar"
								okButtonProps={{ danger:true }}
								onConfirm={() => {
									handleUpdate(r.id, { completed_at: '', cancelled_at: '' })
								}}
							>
								<Tooltip title="Abrir de nuevo" className='text-link'>
									<FontAwesomeIcon icon={faToggleOn} />{" "}
									<span className='hide-sm'>Reanudar</span>
								</Tooltip>
							</Popconfirm>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	const handleUpdate = (id, values) => {
		updateConversation(id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const handleOpenChat = mobile => {
		window.open(`https://wa.me/${mobile}`)
	}

	const copySurveyLink = conversationId => {
		navigator.clipboard.writeText(`Queremos conocer tu opinión. De 1 a 5 como te parecio mi asesoría. ${getClientUri()}/#/qr/encuesta/${conversationId}`)
		message.info('Enlance de encuesta copiado')
	}

	const expandedRowRender = r => (
		<React.Fragment>
			<p className='mb-0'><strong>Punto de Venta: </strong> {(r.link && r.link.store) ? `${r.link.store.name} (${r.link.store.code})` : <em>Sin registro</em>}</p>
			<p className='mb-0'><strong>Asesor: </strong> {r.agent ? `${r.agent.name} (${r.agent.document})` : <em>Sin registro</em>}</p>
			<p className='mb-0'><strong>Id Conversación: </strong> #{r.id}</p>
			<p className='mb-0'><strong>Venta Asociada: </strong> { r.invoice_url ? `${r.invoice_ref} ($ ${number(r.invoice_value)} COP)` : 'Sin venta asociada'}</p>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={conversations}
				rowKey={r => r.id}
				columns={columns}
				loading={!conversations}
				expandable={{ expandedRowRender }}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros"
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			{ registerSaleModal && (
				<RegisterSaleModal 
					visible
					onClose={() => setRegisterSaleModal(null)}
					conversation={registerSaleModal}
					reload={reload}
				/>
			) }
		</React.Fragment>
	)
}